<template>
  <div>
    <main v-if="page" :class="page.padding && [config.padding[page.padding]]">
      <cms-layout-article v-if="page.templateName === 'article'" :page>
        <cms-section v-for="section in page?.sections" :key="section.name" :section page-context />
      </cms-layout-article>

      <cms-section v-for="section in page?.sections" v-else :key="section.name" :section page-context />
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { PageContent } from '#types/page'

definePageMeta({
  validate({ path }) {
    return !/\.\w{2,5}$/.test(path)
  },
  async middleware({ params: { slug }, meta }) {
    const config = useAppConfig().pages.cms.header
    const { getPage, getSeoMetadata } = useCms()
    const cmsPageState = useState<PageContent>('cmsPage')
    const page = await getPage(slug)
    
    if (page) {
      const { metadata, link } = getSeoMetadata(page)
      useSeoMeta(metadata)
      useHead(() => ({ link }))
      cmsPageState.value = page
      meta.header = {
        transparent: config.transparent ? !slug?.[0] : !!page?.name?.includes('[elevate]')
      }
    }
  }
})

const page = useState<PageContent>('cmsPage').value
const route = useRoute()
const config = useAppConfig().pages.cms
const slug = useRouteParams<string>('slug')
const { monetateConfig } = useFeatureFlags()
const { $gtm, $sendExtraMonetateEvents } = useNuxtApp()

// defines markup via unhead-schema for search functionality on homepage
// https://unhead.unjs.io/schema-org/getting-started/how-it-works
useSchemaOrg([
  defineWebSite({
    potentialAction: [
      defineSearchAction({
        target: '/search?q={search_term_string}'
      })
    ]
  })
])

onMounted(async () => {
  $gtm.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageCategory: slug.value ? 'Content' : 'Home',
    pageName: slug.value ? page?.name : 'Home'
  })

  const mainSlug = slug.value?.[0]

  if (monetateConfig?.extraMonetateEventsPages?.includes(mainSlug) || !mainSlug)
    $sendExtraMonetateEvents()

  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', route)
})
</script>
